*, *:before, *:after {
  box-sizing: border-box;
}
body
{
    position: fixed; 
    overflow-y: scroll;
    width: 100%;
}

#sobre-en {
  display: none;
}

#masterMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: none;
}

#container-esquerdo {
  background-color: white;
  width: 50%;
  height: 100vh;
  display: flex;  
  flex-direction: column;
  float: left;
}

#container-direito {
  background-color:#f8f8f8;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#logoSobre {
  width: 21vw;
  margin: 17vh 0 0 5.9vw;
  display:flex;
}

.sobreRihz {
  color:#003072;  
  display: inline-block;
  width: 38vw;
  font-size: 1em;
  font-family:'Open Sans', sans-serif;
  margin: 1.7em 0 0 5.9vw;
  line-height: 1.78vw;
} 

.sobreRihz div {
  margin-top: 1.2em;
}

.sobreRihz p, span {
  opacity: 0.7;
  margin-top: 2em;
}

.sobreRihz h3 {
  margin: 1.9em 0 0 0;
}

#linkedin {
  display: inline-block;
  width: 38vw;
  margin: 1em 0 0 5.9vw;
  line-height: 1.78vw;
}

#linkedin img {
  width: 2.8vw;
  height: auto;
  margin-right: 0.5vw;
}

#logoGrande {
  background: url('./assets/logoGrande.svg') no-repeat;
  background-size: contain;
  background-position: 10vw -10vh;
  width: 100vw;
  height: 100vh;
}

#logoGrande img {
  width: 62vw;
  margin: -26em 0 0 3em;
  display: inline-grid;
}

#LogoPequenaCinza {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#LogoPequenaCinza img {
  width: 1.8vw;
  margin: 0.9em;
}

#lgs {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lg {
  font-size: .7em;
  font-weight: bold;
  cursor: pointer;
  margin-right: .7em;
  box-sizing: border-box;
  width: 2.2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lg_act {
  background-color: #1e84ea;
  color: #fff;
}

.lg_desact {
  border: solid 2px #1e84ea;
  color: #1e84ea;
  box-sizing: border-box;
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {  
  .sobreRihz {
    font-size: 0.7em;
  }

  #logoGrande img {
    width: 62vw;
    margin: -20em 0 0 3em;
    display: inline-grid;
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {  
  .sobreRihz {
    font-size: 0.7em;
  }

  #logoGrande img {
    width: 62vw;
    margin: -10.6em 0 0 3em;
    display: inline-grid;
  }
}

/* ----------  mobile ------------ */
@media (max-width: 768px) { 
  #masterMobile {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #sobre-en-mb {
    display: none;
  }

  #masterWeb {
    display: none;
  }  
  
  .lg_mb {
    font-size: .7em;
    font-weight: bold;
    cursor: pointer;
    margin-right: 1.7em;
    box-sizing: border-box;
    width: 3em;
    height: 2.9em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #id_pt_mb {
    margin: 1.4em;
  }
  
  .lg_mb_act {
    background-color: #fff;
    color: #1e84ea;
  }
  
  .lg_mb_desact {
    border: solid 2px #fff;
    color: #fff;
    box-sizing: border-box;
  }
    
  #topoRosaMobile {
    width: 90vw;
    height: 8vh; 
    position: fixed;
    left: 0%;
    top: 0%;
    background-color: #FF337A;
  }

  .sobreRihzMobile {
    color:#003072;  
    display: flex;
    flex-direction: column;
    margin-top: 15%;
    position: relative;
    width: 80%;
    height: 70%;
    font-size: 1.2em;
    font-family:'Open Sans', sans-serif;
    line-height: 1.78em;
  } 

  #logoSobreMobile {
    height: 10vh;
    margin: auto 0 7vw 0;
  }  

  .sobreRihzMobile p, span {
    opacity: 0.7;
    margin-top: 2em;
  }

  .sobreRihzMobile h3 {
    margin: 1.9em 0 0 0;
  }

  #linkedinMobile {
    display: inline-block;
    width: 38vw;
    margin: 1em 0 0 0;
    line-height: 1.78vw;
  }

  #linkedinMobile img {
    width: 6vw;
    height: auto;
    margin-right: 0.5vw;
  }

  #LogoPequenaCinza img {
    float: right;
    width: 1.8vw;
    margin: 0.9em 0.9em 0 0;
  }

  #baixoAzulMobile {
    width: 90vw;
    height: 8vh;
    background-color: #1e84ea;
    position: fixed; 
    bottom:0%;
    right: 0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    }

  #baixoAzulMobile img{
    margin: 0em 1em 0em 1em;
  }

  #lgs_mb {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  
  #topoRosaMobile {
    height: 8vh;
  }

  .sobreRihzMobile {
    font-size: 0.8em;
  } 

  #logoSobreMobile {
    height: 6vh;
    margin: 5vh 0 10vw 0vw;
  }

  #baixoAzulMobile {
    height: 8vh;
  }

  #baixoAzulMobile img{
    height: 3vh;
    margin: 0em 1em 0em 1em
  }
}

@media (max-width: 400px) {
  
  #topoRosaMobile {
    height: 7vh;
  }

  .sobreRihzMobile {
    font-size: 0.8em;
  } 

  #logoSobreMobile {
    height: 6vh;
    margin: 5vh 0 10vw 0vw;
  }

  #baixoAzulMobile {
    height: 7vh;
  }

  #baixoAzulMobile img{
    height: 3vh;
    margin: 0em 1em 0em 1em;
  }
}

@media (max-width: 374px) {

  #logoSobreMobile {
    height: 6vh;
    margin: 2vh 0 7vw 7vw;
  }
 
}

@media (max-width: 340px) {
  
  #topoRosaMobile {
    height: 7vh;
  }

  .sobreRihzMobile {
    font-size: 0.7em;
  } 

  #logoSobreMobile {
    height: 6vh;
    margin: 0 0 7vw 7vw;
  }

  #baixoAzulMobile {
    height: 7vh;
  }

  #baixoAzulMobile img{
    height: 3vh;
    margin: 0em 1em 0em 1em;
  }
}
